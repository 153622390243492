/*****************************
  Action Box
*****************************/

.callout { 
	background: $gray-9; 
	box-shadow: $box-shadow-sm; 
	border-radius: $border-radius; 
	padding: 50px 0px 50px 100px; 
	margin-right: 85px; 
	.callout-title { 
		align-self: center;  
    }
	h3{ 
		color: $white; 
		margin-bottom: 0; 
		text-transform: inherit; 
		letter-spacing: -0.8px; 
	}
	.callout-botton { 
		margin-right: -85px; 
		text-align: right; 
		.btn { 
			padding: 26px 28px; 
			i { 
				font-size: 20px;
			}
		}
	}
}

