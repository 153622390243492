/*****************************
  Blockquote
*****************************/

.blockquote {
	font-size: 16px;
	border-left: 10px solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
}

.blockquote-quote {
	position: relative;
	font-size: 18px;
	border-left: 0 solid $primary;
	padding-left: 30px;
	font-style: italic;
	display: block;
	z-index: 1;
	p {
		position: relative;
	}
	i {
		position: absolute;
		z-index: 0;
		top: 20px;
		left: 0;
		font-size: 60px;
		line-height: 60px;
		color: $gray-2;
	}
}

/* blockquote-01 */

.blockquote-01{
	padding: 40px 0px 60px 0;
    position: relative;
    margin: 0 30px 0 0px;
	.blockquote-info{
		padding: 40px 40px;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		position: relative;
		background-color: $white;
		.blockquote-quote{
			position: absolute;
			top: -60px;
			left:12%;
			transform: translateX(-50%);
			z-index: -1;
			i{
	        	font-size: 90px;
	    	    color: rgba( $gray-8,0.2);
	         }
		}
		.blockquote-content {
			font-size: 14px;
			font-weight: 500;
			position: relative;
			margin-bottom: 0px;
			color: $gray-3
		}
	}
}


/* blockquote 02 */

.blockquote-02 {
	padding: 70px 0px;
	text-align: center;
    position: relative;
    margin: 0 30px;
	.blockquote-info{
		padding: 70px 80px;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		position: relative;
		background-color: $white;
		.blockquote-quote{
			position: absolute;
			top: -60px;
			left:50%;
			transform: translateX(-50%);
			z-index: -1;
	     	i{
	        	font-size: 90px;
	    	    color: rgba( $gray-8,0.2);
	         }
		}
		.blockquote-content {
			font-size: 18px;
			font-style: italic;
			font-weight: 600;
			position: relative;
			margin-bottom: 0px;
		}
		.blockquote-author{
			margin-bottom:0;
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			bottom: -70px;
			.blockquote-name{
				h6{
					font-weight: 400;
					margin-right: 10px;
					display: inline-block;
				}
				span{
					font-size: 16px;
					font-weight: 400;
					color: $gray-2;
				}
			}
		}
	}
}

@media (max-width:767px) {
	.blockquote-01 {
		margin: 0px;
		.blockquote-info {
			padding: 20px;
		}
	}

	.blockquote-02 {
		margin: 0 20px;
		.blockquote-info {
			padding: 30px 20px;
		}
	}
}


