/*****************************
  		Blog
*****************************/

.blog-post{
	margin-bottom:30px;
	.blog-post-image{
		img{
			border-radius: $border-radius;
		}
	}
	.blog-post-content{
		text-align: center;
		padding: 25px 30px 40px;
		margin: -45px 25px 0;
		box-shadow: $box-shadow;
		background-color: $white;
		border-radius: $border-radius;
		position: relative;
		z-index: 9;
		transition: all 0.5s ease-in-out;
			.blog-post-date{
				font-size: 12px;
				font-weight: 700;
				color:$gray-3;
				margin-bottom: 8px;
			}
			.blog-post-title{
				font-weight: 700;
				margin-bottom: 0;
			}
	}
	&:hover{
		.blog-post-content{
			background-color: $primary;
			transition: all 0.5s ease-in-out;
			margin-top: -60px;
			.blog-post-title{
				a{color: $gray-8;}
			}
		}
	}
}

/* Blog Detail */

.blog-detail{
	.blog-post{
		margin-bottom:0px;
		.blog-post-content{
			margin-top: 0;
			top: -45px;
		}
		&:hover{
			.blog-post-content{
				margin-top: 0;
				top: -60px;
			}
		}
	}
	hr{
		margin-top: 70px;
		margin-bottom: 40px;
	}
	.blog-post-social{
		display: flex;
		justify-content: center;
		margin-bottom: 30px;
		span{
			padding-right:15px;
		}
		ul{
			margin-bottom: 0;
			li{
				display: inline-block;
				float: left;
				padding: 0 6px;
				a{
					color: $gray-8;
					&:hover{
						color:$primary;
					}
				}
			}
		}
	}
	.blog-post-navigation{
		.post-navigation{
			.nav-link{
				position: relative;
				a{
					i{
						position: absolute;
						font-size: 36px;
						color:  $gray-8;
						top: 50%;
						transform: translateY(-50%);
					}
					span{
						display: block;
						color: $gray-8;
						transition: all 0.5s ease-in-out;
					}
					.nav-title{
						font-size: 18px;
					}
				 }
				.nav-previous{
					position: absolute;
					left: 0;
					a{
						position: relative;
						display: block;
						padding-left: 90px;
						padding-top: 11px;
						padding-bottom: 11px;
						i{
							left: 20px;
						}
						&:before{
							position: absolute;
							content: "";
							top: 50%;
							transform: translateY(-50%);
							left: 0;
							width: 40px;
							height: 100%;
							box-shadow: $box-shadow;
							background-color: $white;
							border-radius: $border-radius;
							transition: all 0.5s ease-in-out;
						}
						&:hover{
							span{
								color:$primary;
							}
							&:before{
								background-color:$primary;
							}
						}
					}
				}
				.nav-next{
					position: absolute;
					right: 0;
					text-align: right;
					a{
						position: relative;
						display: block;
						padding-right: 90px;
						padding-top: 11px;
						padding-bottom: 11px;
						i{
							right: 20px;
						}
						&:before{
							position: absolute;
							content: "";
							top: 50%;
							transform: translateY(-50%);
							right: 0;
							width: 40px;
							height: 100%;
							box-shadow: $box-shadow;
							background-color: $white;
							border-radius: $border-radius;
							transition: all 0.5s ease-in-out;
						}
						&:hover{
							span{
								color:$primary;
							}
							&:before{
								background-color:$primary;
							}
						}
					}
				}
			}
		}
	}
	.comments-01{
	  display: flex;
	  	.comment-author{
	  		width: 90px;
	  		img{
		  		width: 100%;
		  		border-radius:$border-radius;
	  		}
	  	}
	    .comment-content{
	      border: 1px solid $border-color;
	      padding: 15px;
	      margin-left: 20px;
	      margin-bottom: 20px;
	      width: 100%;
	      .reviews{
	        display: flex;
	        margin-bottom: 10px;
	        align-items: center;
	        p{
	        	margin-bottom: 0;
	        }
	        .port-social{
	          margin-left: auto;
	          border-radius: $border-radius;
	          color: $white;
	          background: $gray-9;
	          padding: 5px 15px;
	          display: block;
	        }
	      }
	    }
	}
	.comments-02{
	  display: flex;
	  padding-left: 100px;
	  .comment-author{
	  		width: 90px;
		  img{
	  		width: 100%;
	  		border-radius:$border-radius;
		  }
		}
	    .comment-content{
	      border: 1px solid $border-color;
	      padding: 15px;
	      margin-left: 20px;
	      margin-bottom: 20px;
	      width: 100%;
	      .reviews{
	        display: flex;
	        margin-bottom: 10px;
	        align-items: center;
	        p{
	        	margin-bottom: 0;
	        }
	        .port-social{
	          margin-left: auto;
	          border-radius: $border-radius;
	          color: $white;
	          background: $gray-9;
	          padding: 5px 15px;
	          display: block;
	        }
	      }
	    }
	}
}

/* Blog Sidebar */

.blog-sidebar{
	box-shadow: $box-shadow;
	background-color: $white;
	border-radius: $border-radius;
	padding: 40px;
	.widget{
		margin-bottom: 50px;
		.widget-title{
			margin-bottom: 30px;
		}
		.search{
			position: relative;
			.form-control{
				background-color: $gray-9;
				color: $white;
				border: 1px solid $gray-9;
				padding-right: 60px;
			}
			i{
				position: absolute;
			    right: -1px;
			    top: 0;
			    background:$primary;
			    color:  $gray-8;
			    padding: 17px 16px;
			    cursor: pointer;
			    border-radius: 0 5px 5px 0px;
			}
		}
		.widget-categories{
			ul{
				li{
					border-bottom:1px solid rgba($gray-3,0.1) ;
					padding-bottom: 15px;
					margin-bottom: 25px;
					a{
						display:block;
						color:$gray-8;
						&:hover{
							color:$primary;
						}
					}
					&:last-child{
						margin-bottom: 0;

			border-bottom: 0;
    		padding-bottom: 0;
					}
				}
			}
		}
		.recent-posts{
			a{
				font-size: 13px;
				color:$gray-8;
				&:hover{
					color:$primary;
				}
			}
			.date{
				font-size: 11px;
			}
		}
		.tagcloud{
			ul{
				li{
				  display: inline-block;
		          border: 1px solid $border-color;
		          padding: 0 12px;
		          margin-bottom: 10px;
		          border-radius: $border-radius;
		          transition: all 0.3s ease-in-out;
		          a{
		          	color: $gray-3;
		          	line-height: 32px;
		          }
		          &:hover{
		            background: $primary;
		            border: 1px solid $primary;

		             a{
		              	 color: $white;
		             }
		         }
				  }
			 }
		}
		.social{
			ul{
				margin-bottom: 0;
				li{
					display: inline-block;
					list-style-type:none;
					padding: 0 15px;
					a{
						font-size: 18px;
						color:$gray-8;
						&:hover{
							color:$primary;
						}
					}
					&:last-child{
						padding-right: 0;
					}
					&:first-child{
						padding-left: 0;
					}
				}
			}
		}

		&:last-child{
			margin-bottom: 0;
		}
	}
}

/* blog-author */

.blog-author .author{
	display: flex;
	text-align: left;
	align-items: center;
	color: $white;
	padding: 30px 30px 30px 0;
	border-radius: $border-radius;
	margin-left: 40px;
	.author-image {
		flex: 0 150px;
		margin-left: -40px;
		img{
			border-radius: $border-radius;
		}
	}
	.author-detail{
		position: relative;
		padding: 40px 30px 20px;
		padding: 0 0 0 30px;
	    flex: 0 70%;
	    align-self:center;
	    .author-title a{
			color: $white;
		}
		.author-label{
   			color: $white;
		}
		.author-social {
			margin-top: 20px;
			ul{
				display: inline-block;
				margin-bottom: 0;
				padding-left: 0;
				li{
					display: inline-block;
					list-style-type: none;
					float: left;
					a{
						color: $white;
						margin-right: 20px;
					}
				}
			}
		}
		.author-content{
			margin-top: 15px;
		}
	}
}


@media (max-width:767px) {
	.blog-sidebar {
		padding: 20px;
	}
}


