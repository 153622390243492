/*****************************
		Service
*****************************/

.service-style-01{
	margin-bottom: 50px;
		img{
			border-radius: $border-radius;
		}
	.service-title{
		text-align: center;
		padding: 40px 10px;
		margin: -30px 25px 0;
		box-shadow: $box-shadow;
		background-color: $white;
		border-radius: $border-radius;
		position: relative;
		z-index: 9;
		font-weight: 700;
		margin-bottom: 0;
		transition: all 0.5s ease-in-out;
		display: block;
		color: $gray-8;
		font-size: 18px;
		line-height: 1.2;
		&:hover{
			color: $gray-8;
		}	
	}
	&:hover{
			.service-title{
				background-color: $primary;
				margin-top: -45px;
		}
	}
}

/* service-style-02 */ 

.service-style-02 {
	display: flex; 
	background: $white; 
	box-shadow: $box-shadow;
    border-radius: $border-radius; 
    padding: 50px 50px 50px 0px; 
    margin-left: 50px; 
    cursor: pointer; 
    margin-bottom: 30px;
    align-items: center;
    transition: all 0.5s ease-out 0s;
    &:hover { 
		background: $primary; 
		.btn.btn-outline-primary{
			color: $gray-8;
			border-color: $white;
			background: $white;
		}
	}
	.service-image{ 
		margin-left: -50px;
		flex:50%;
		img{ 
			border-radius: $border-radius;
		 }
	}
	.service-title { 
		margin-bottom: 20px; 
	}
	.service-content { 
		flex:50%;
		margin-left: 50px;
		align-self: center; 
		p{ 
			color: $gray-8; 
			margin-bottom: 20px; 
		}
	}
}





