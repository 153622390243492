/*****************************
  Category
*****************************/

.category-style-01{
	margin-bottom: 30px;
	img{
		border-radius: $border-radius;
	}
	.category-contant{
		text-align: center;
		padding: 10px 10px;
		margin: -30px 25px 0;
		box-shadow: $box-shadow;
		background-color: $white;
		border-radius: $border-radius;
		position: relative;
		z-index: 9;
		font-weight: 700;
		margin-bottom: 0;
		display: block;
	}
	&:hover{
		.category-contant{
			background-color: $primary;
			transition: all 0.5s ease-in-out;
		}
	}
}