/*****************************
  Counter
*****************************/

.counter {
	padding: 40px; 
	box-shadow: $box-shadow; 
	text-align: center;
	background-color:$white;
	border-radius: $border-radius;
	margin-bottom: 25px;
	.counter-content{
		.timer {
			position: relative;
			font-size: 48px;
			font-weight: 600;
			font-family: $font-hedding;
			color: $gray-8;
		}
		label{
			color: $gray-3;
			font-size: 16px;
			font-weight: 600;
			display: block;
		}
	}
	.counter-icon{
		i{
			font-size: 55px;
			color: $primary;
		}
	}
}

