/*****************************
  Button
*****************************/

.btn {
	transition: all 0.3s ease-in-out;
}
button {
	outline: medium none !important;
	color: $primary;
}

.btn-link {
	text-decoration: none !important;
}

/* btn */

.btn {
	font-size: 16px;
	font-weight: 700;
	padding: 10px 35px;
	border-radius: $border-radius;
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				box-shadow: none;
				outline: none;
			}
		}
		&:not(.disabled) {
			&:active {
				&:focus {
					box-shadow: none;
					outline: none;
					color: $gray-9;
				}
			}
		}
	}
	&:hover {
		box-shadow: none;
		outline: none;
	}
	&:focus {
		box-shadow: none;
		outline: none;
		color: $white;
	}
	&:active {
		box-shadow: none;
		outline: none;
		color: $white;
	}
}
.show {
	>.btn.dropdown-toggle {
		&:focus {
			box-shadow: none;
			outline: none;
			color: $white;
		}
	}
	>.btn-primary.dropdown-toggle {
		background: $primary;
		border-color: $primary;
	}
}

/* btn-primary */

.btn-primary {
	background: $primary;
	border-color: $primary;
	color: $gray-8;
	&:hover {
		background: darken($primary, 6%);
		border-color: darken($primary, 6%);
	  	color: $gray-8;
		&:not(:disabled) {
			&:not(.disabled).active {
				background: $primary;
				border-color: $primary;
			}
			&:not(.disabled) {
				&:active {
					background: $primary;
					border-color: $primary;
				}
			}
		}
	}

	&:focus {
		background: $primary;
    	border-color: $primary;
	}

}
.btn-primary:not(:disabled):not(.disabled):active:focus {
	background: $primary;
	border-color: $primary;
}

/* btn-dark */

.btn-dark {
	background: $gray-9;
	border-color: $gray-9;
	&:hover {
		background: lighten($gray-9, 5%);
		border-color: lighten($gray-9, 5%);
	}

}

.btn-dark:not(:disabled):not(.disabled):active:focus {
    color: $white;
}


.btn {
	&:not(:disabled) {
		&:not(.disabled).active {
			&:focus {
				color: $white;
			}
		}
	}

	i {
		padding:0px 10px 0px 0px;
	}
	+ {
		.btn {
			margin-left: 3px;
		}
	}
}

.btn-link {
	color: $primary;
	&:hover {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}
	&:focus {
		color: $gray-9;
		text-decoration: none;
		outline: none;
	}
}

.btn.btn-link:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

/* btn-white */

.btn-white {
	background: $white;
	border-color: $white;
	color: $gray-9;
	&:hover {
		background: $gray-2;
		border-color: $gray-2;
		color: $gray-9;
	}
	&:active {
		color: $gray-9;
	}
	&:focus {
		color: $gray-9;
	}
}

.btn.btn-white:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}

/* btn-light */

.btn-light {
	&:active {
		color: $gray-9;
	}
	&:focus {
		color: $gray-9;
	}
}

.btn.btn-light:not(:disabled):not(.disabled):active:focus {
	color: $gray-9;
}
.btn.btn-sm {
	padding: 6px 24px;
}
.btn.btn-md {
	padding: 8px 30px;
}
.btn.btn-lg {
	padding: 12px 35px;
	font-size: 18px;
}
.btn.btn-xl {
	padding: 15px 60px;
	font-size: 18px;
}
.btn-app {
	display: flex;
	font-size: 14px;
	i {
		font-size: 30px;
	}
}

/* btn-outline-secondary */

.btn-outline-secondary {
	border: 2px solid $border-color;
	color: $gray-8;
	&:hover {
		background: $gray-8;
		color: $white;
		border-color: $gray-8;
	}
	&:focus {
		background: $gray-8;
		color: $white;
		border-color: $gray-8;
	}
}

.btn-outline-secondary:not(:disabled):not(.disabled):active {
	background: $gray-8;
	color: $white;
	border-color: $gray-8;
}
.btn-outline-primary {
	color: $gray-8;
	border-color: $primary;
	&:hover {
		background: $white;
		color: $gray-8;
		border-color: $white;
	}
	&:focus {
		background: $white;
		color: $gray-8;
		border-color: $white;
	}
}

.btn-outline-primary:not(:disabled):not(.disabled):active {
	background: $white;
	color: $gray-8;
	border-color: $white;
}

/* btn-arrow */

.btn-arrow{
	position: relative;
	padding: 15px 50px;
	font-size: 18px;
	&:hover{
		background-color: $primary;
		border-color: $primary;
	}
	i{
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		transition: all 0.3s ease-in-out;
		right: 0px;
		opacity: 0;
		font-size: 30px;
	}
}

.btn-arrow:hover i{
	opacity: 1;
	right: -25px;
}
