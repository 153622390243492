/*****************************
	Tabs
*****************************/

.nav-tabs {
	border: none;
	.nav-item {
		margin-bottom: 0;
		margin-right: 15px;
		.nav-link {
			border: none;
			font-size: 24px;
			padding: 25px 40px;
			font-family: $font-hedding;
			font-weight: 700;
			color: $white;
			background-color: $gray-9;
			box-shadow: $box-shadow; 
			border-radius: $border-radius;
			transition: all 0.5s ease;
			&:hover{
				color: $gray-8;
				background-color: $primary;
				transition: all 0.5s ease-in-out;
			}
		}
		.nav-link.active {
			color: $gray-8;
			background-color: $primary;
		}
	}
	.nav-item:last-child{
		margin-right: 0px;
	}
}

.tab-content{
	#choose-us{
		margin-top:70px;
	}
	#question{
		margin-top:55px;
	}
}

/* nav-tabs-02 */
.nav-tabs.nav-tabs-02 {
	border: none;
	.nav-item {
		margin-bottom: 0;
		.nav-link {
			background: $gray-9;
			border: none;
			color:  $white;
			padding: 10px 20px;
			border-radius: $border-radius;
		}
		.nav-link.active {
			background: $primary;
			border: none;
			color: $gray-8;
		}
	}
}


