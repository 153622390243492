// Font family
$font-base: 		'Quicksand', sans-serif;
$font-hedding:	'Quicksand', sans-serif;

// Colors
$body-color:	  #666666;
$primary:				#F48521;
$white:					#ffffff;
$gray-1: 				#fafafa; 	// bg light
$gray-2: 				#dfdfdf;
$gray-3: 				#666666; 	// body-text
$gray-4: 				#707173;
$gray-5: 				#3a4957;
$gray-6: 				#293745;
$gray-7: 				#1b1b29;
$gray-8: 				#283B90; 	// Hedding color
$gray-9: 				#283B90; 	// bg color
$black:  				#000000;

$border-color: 	#eeeeee;

//  For button and input border radius
$border-radius: 5px;

//  Define common padding and border radius sizes and more.

$box-shadow-sm:	0px 5px 16px 0px rgba($gray-7, .15);
$box-shadow:	  0px 8px 27px 0px rgba($gray-7, .15);
$boxshadow-lg:  0 1rem 3rem rgba($gray-7, .175);

