/*****************************
  List Style
*****************************/

.list{
  ul {
    padding: 0;
    margin: 0;
    li{
	    list-style: none;
	    position: relative;
	    padding-left: 25px;
	    margin-bottom: 12px;
	    i{
	    	position: absolute;
    		top: 4px;
    		left: 0;
    		font-size: 13px;
	    }
     	&:last-child {
 	    margin-bottom: 0;
      }
    }
  }
}






