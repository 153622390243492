/*****************************
	Team
*****************************/

.team{
	position: relative;
	transition: all 0.5s ease-in-out;
	text-align: center;
	.team-image{
		img{
			border-radius: $border-radius;
		}
	}
	.team-detail{
		position: relative;
		padding: 40px 30px 20px;
		.team-social{
			position: absolute;
		    top: -20px;
		    left: 50%;
		    width: 100%;
		    transform: translateX(-50%);
		    opacity: 0;
		    transition: all 0.5s ease-in-out;
			ul{
				display: inline-block;
				margin-bottom: 0;
				padding-left: 0;
				li{
					display: inline-block;
					list-style-type: none;
					float: left;

					&:last-child{
						a{
							margin-right: 0;
						}
					}
					a{
						display: block;
						background-color:$primary;
						color: $gray-8;
						height: 40px;
						width: 40px;
						line-height: 45px;
						text-align: center;
						border-radius:50%;
						margin-right: 20px;
						transition: all 0.5s ease;
						&:hover{
							background-color:$gray-9;
							color:$primary;
						}
						i{
							font-size: 18px;
						}
					}
				}
			}
		}
		.team-title{
			margin-bottom: 4px;
		}
		.team-label{
			font-size: 12px;
			color: #999999;
		}
		.team-content{
			margin-top: 15px;
		}
	}
	&:hover{
		.team-detail{
			.team-social{
				opacity: 1;
			}
		}
	}
}
