/*****************************
	Pricing Table
*****************************/

.pricing-table-style-01{
	box-shadow:$box-shadow;
	border-radius:$border-radius;
	text-align: center;
	padding: 45px;
	h4{
		margin-bottom: 20px;
	}
	p{
		margin-bottom: 20px;
	}
	.pricing-price{
		sup{
			font-size: 24px;
			font-weight: 500;
			top: -2.5rem;
			right: 5px;
		}
		h2{
			font-size: 80px;
			span{
				font-size: 14px;
				color: $gray-3;
			}
		}
	}
	.pricing-list-style {
		padding: 35px 0;
			li{
				margin-bottom: 10px;
				font-weight: 600;
				list-style: none;
			}
		}
}

.pricing-table-style-01.active{
	background: $primary;
	 a{
	    background: $white;
    	border-color: $white;
    	color: $gray-9;
    	&:hover {
		    background: $gray-9;
		    border-color: $gray-9;
		    color: $white;
		}
	}
}

/* pricing-table-style-02 */

.pricing-table-style-02{
    border-radius: $border-radius;
    box-shadow:$box-shadow;
    background: $white;
    .pricing-table{
    	text-align: center;
    	padding: 60px 0px;
    	border-radius: $border-radius;
    	p{
    		padding: 0px 30px;
    	}
    	.pricing-prize{
    		border-radius: $border-radius;
    		box-shadow:$box-shadow-sm;
			h2{
				font-size: 48px;
				padding: 15px;
	    		sup{
					 top: -1.5rem;
				    font-size: 16px;
				    font-weight: 500;
				    right: 5px;
				}
				span{
					font-size: 14px;
					color: $body-color;
				}
			}
   		 }
		.pricing-list-style {
			.list-unstyled{
			    padding: 20px 0px 30px 0px;
			    margin-bottom: 0;
				li{
				 	margin-bottom: 30px;
					font-size: 16px;
				}
		    }
		}
	}
	.btn{
		padding: 0;
		color: $gray-9;
	}
}

.pricing-table-style-02 .pricing-table {
	a.btn:hover{
		color: $primary;
	}
}

.pricing-table-style-02 .pricing-table.active {
	.pricing-prize{
		background: $primary;
	}
	a{
		color: $primary;
	}
}

/* pricing-table-style-03 */

.pricing-table-style-03{
	display: flex;
	align-items: center!important;
	padding: 20px 50px;
	box-shadow:$box-shadow;
	border-radius:$border-radius;
	margin-bottom: 20px;
	.pricing-table-title{
		flex: 0 0 20%;
		margin-right: 20px;
	}
	.pricing-prize.pricing-table-price{
		flex: 0 0 20%;
		h2{
			font-size: 80px;
			sup {
				font-size: 24px;
				font-weight: 500;
				top: -2.5rem;
				right: 5px;
			}
			span{
				font-size: 14px;
    			color: $body-color;
			}
		}
	}
	.pricing-table-content{
		flex: 0 0 39%;
		margin-right: 30px;
		.pricing-list-item{
			flex: 0 0 32%;
			margin-right: 5px;
		}
		.list-unstyled{
			display: flex;
			flex-wrap: wrap;
			margin-right: 10px;
			margin: 0;
			padding: 40px 0;
			li{
				margin-bottom: 10px;
			}
		}
	}
	.pricing-table-button{
		flex: 0 0 18%;
	}
}

.pricing-table-style-03.active{
	background: $primary;
	.pricing-table-button a{
		background: $white;
		color: $gray-9;
		border-color: $white;
	}
	.pricing-table-button a:hover{
		background: $gray-9;
		color: $white;
		border-color: $gray-9;
		transition: all 0.5s ease-in-out;
	}
}

