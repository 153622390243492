/*****************************
  Shortcodes
*****************************/

/*****************************
  Accordion
*****************************/

.accordion {
	.card{
		border: none;
		padding:15px;
		.card-header{
			background: none;
			border-bottom:none;
			padding: 0;
			button{
				position: relative;
				font-size: 24px;
				color: $gray-8;
				padding:17px 30px 17px 100px;
				width: 100%;
				text-align: left;
				border: none;
				&:before{
					position: absolute;
					top: 50%;
					transform: translateY(-50%);
					left: 25px;
					content: "\f068";
					font-size: 14px;
					font-family: "Font Awesome 5 Free";
					border: 1px solid $gray-9;
					width: 36px;
					height: 36px;
					line-height: 36px;
					border-radius: 50%;
					z-index: 9;
					font-weight: 900;
					text-align: center;
				}
				&:after{
					width: 45px;
					box-shadow: $box-shadow-sm;
					border-radius: $border-radius;
					position: absolute;
					content: "";
					left: 0;
					top: 0;
					background: $primary;
					height: 100%;
					transition: all 0.5s ease-in-out;
				}
			}
		}
		.card-body {
			padding: 10px 30px 0px 100px;
		}
	}
}
.accordion .card .accordion-icon.card-header button.collapsed:after {
	background: $white;
}
.accordion .accordion-icon.card-header button.collapsed:before{
	content: "\f067";
}

/* accordion-02 */

.accordion.accordion-02{
	.card{
		padding:8px 0px;
	.card-header{
		button{
			font-size: 14px;
			padding: 0px 0px 0px 30px;
			&:before{
             width: 15px;
             height: 15px;
             line-height: 12px;
             font-size: 8px;
             left: 0px;
			}
			&:after{
				display: none;
		}
	  }
	}
	.card-body {
		padding: 10px 0px 0px 30px;
	}

  }
}




