/*****************************
  Feature Info
*****************************/

.feature-info {
	position: relative;
	transition: all 0.5s ease-in-out;
	padding: 0px 10px;
	.feature-info-icon {
		position: relative;
		height: 100px;
		width: 105px;
		font-size: 40px;
		margin-bottom: 10px;
		color: $primary;
		display: inline-block;
		padding-top: 25px;
		&:before{
			position: absolute;
			content: "";
			height: 60%;
			width: 100%;
			left: 50%;
			transform: translateX(-50%);
			top: 0;
			z-index: -1;
			background-color: $white;
			border-radius: $border-radius;
			box-shadow: $box-shadow;
			transition: all 0.5s ease-in-out;
		}
		i{
			font-size: 60px;
			color: $gray-8;
			position: absolute;
		    top: 50%;
		    left: 50%;
		    transform: translate(-50%,-50%);
		}
	}
	.feature-info-content {
		.feature-info-title{
			margin-bottom: 20px;
		}
	}
	&:hover {
		.feature-info-icon {
			&:before{
				background-color: $primary;;
			}
		}
	}
}

.feature-info .feature-info-content p{
	margin-bottom: 0;
}

.feature-info.left-icon{
	display: flex;
	padding: 0px 40px 0px 0px;
	margin-bottom: 50px;
	.feature-info-icon{
		left: 0;
		height: 105px;
		line-height:105px;
		margin-bottom: 0;
		padding-top: 0;
		flex: 120px 0 0;
		i{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50% , -50%);
		 }
		&:before{
			height: 100%;
			width: 50%;
			left: 0;
			transform: translateX(0);
			top: 0;
		}
	}
	.feature-info-content{
		padding-left: 20px;
	}
}

/* feature-info-box */

.feature-info-box {
    margin-bottom: 45px;
}
.feature-info-box.right-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    align-items: center;
    padding: 20px 15px;
    justify-content: flex-end!important;
	&:hover {
		background: $primary;
		transition: all 0.5s ease-in-out;
	}
	h4{
		margin-bottom: 0;
		margin-right:20px;
	}
}
.feature-info-box.left-icon {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    background: $white;
    box-shadow: $box-shadow;
    border-radius: $border-radius;
    align-items: center;
    padding: 20px;
    justify-content: flex-start!important;
	&:hover {
		background: $primary;
		transition: all 0.5s ease-in-out;
	}
	h4{
		margin-bottom: 0;
		margin-left:20px;
	}
}



/* feature-step-list */

.feature-step-list{
	display: inline-block;
	padding: 0;
}

.feature-step-list{
	margin-bottom: 0px;
	li{
		width: 33.333333%;
		float:left;
		list-style-type:none;
		&:last-child{
			margin-bottom: 0px;
		}
	.feature-step{
		position:relative;
		transition: all 0.5s ease-in-out;
		.feature-step-divider{
			position: relative;
			&:before{
				content: "";
				border-top: 3px dotted $primary;
				width: 80px;
				position: absolute;
				top: 60px;
				right: -11%;
			}
			.feature-step-icon {
				position: relative;
				height: 105px;
				width: 105px;
				font-size: 40px;
				margin-bottom: 10px;
				color: $primary;
				display: inline-block;
				padding-top: 25px;
				background-color: $primary;
				border-radius: $border-radius;
				i{
					position: absolute;
					top: 35px;
					right: 0;
					left: 0px;
					color: $gray-8;
					font-size: 70px;
				}
				.feature-step-number{
					background: $gray-9;
					color: $white;
					border-radius:50%;
					position:absolute;
					top: -15px;
					width:35px;
					height:35px;
					line-height:35px;
					right: -15px;
					font-size: 18px;
					font-weight:700;
				}
			}
		}
		.feature-info-content{
			padding: 0px 40px;
			h4{
				margin: 40px 0px 20px;
			}
		}
	}
	&:last-child{
			.feature-step-divider{
				&:before{
					display: none;
				}
			}
		}
	}
}

.info-box {
	border-radius: $border-radius;
	box-shadow: $box-shadow;
	padding: 40px;
	.feature-info-content h4 {
		margin-bottom: 20px;
		font-weight: 700;
	}
	.feature-info-icon {
		margin-bottom: 25px;
		color: $gray-8;
		i {
			font-size: 60px;
		}
	}
}

.feature-info .feature-info-content span{
	display: block;
}

