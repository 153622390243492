/*****************************
  Layout
*****************************/
/*****************************
  Section Title
*****************************/

.section-title {
	margin-bottom: 50px;
	.title{
		margin-bottom: 30px;
	}
	h6{
		margin-bottom: 30px;
		line-height: 26px;
	}
}
.header-inner{
	position: relative;
	padding: 60px 0 0;
	margin-bottom: 30px;
	.inner-banner-title{
		font-size: 42px;
		display: inline-block;
		background-color: $white;
		border-radius: $border-radius;
		box-shadow: $box-shadow;
		padding: 20px 50px;
		transform: translateY(40px);
	}
}

/* home-01 */

.pricing-section-title{
	padding-top: 170px;
	padding-bottom: 190px;
}

.pricing-section {
	position: relative;
	z-index: 9;
	top: -137px;
	margin-bottom: -137px;
}

.clients-section{
	position: relative;
	z-index: 9;
	margin-bottom: -70px;
}

.our-clients{
	padding: 40px 50px;
	background-color: $primary;
	border-radius: $border-radius;
	.item{
		background:$white;
		border-radius: $border-radius;
	    padding: 0 25px;
	    height: 65px;
	    display: flex;
	    align-items: center;
	}
}

.bg-overlay-left{
	position: relative;
	&:before{
		position: absolute;
		content: "";
		top: 0;
		left: 0;
		background-color:$gray-9;
		width: 38%;
		height: 63%;
		border-radius: 0 5px 5px 0;
	}
	.section-contant{
		padding-left: 70px;
	}
}

.bg-overlay-left.bg-overlay-light{
	&:before{
		background-color:$gray-1;
	}
}

.bg-overlay-right{
	position: relative;
	&:before{
		position: absolute;
		content: "";
		top: 0;
		right: 0;
		background-color:$gray-9;
		width: 38%;
		height: 63%;
		border-radius: 5px 0px 0px 5px;
	}
}

.bg-overlay-right.bg-overlay-light{
	&:before{
		background-color:$gray-1;
	}
}

/* Home-02 */
.team-section-title{
	padding-top: 120px;
	padding-bottom: 150px;
}

.team-section{
	position: relative;
	z-index: 9;
	top: -137px;
	margin-bottom: -137px;
}

.clients-white-bg{
	padding: 40px 50px;
	border-radius: $border-radius;
}

.feature-section .btn{
	margin-top: 100px;
}

.services-section {
	padding-left: 160px;
	padding-right: 160px;
	.owl-carousel .owl-dots .owl-dot span{
		background: $white;
	}
	.owl-carousel .owl-dots .owl-dot.active span{
		background:$primary;
	}
}

.counter-section {
    padding:40px 70px 40px 30px;
    &:before{
    position: absolute;
    content: "";
    top: 0;
    right: 15px;
    background-color: $primary;
    width: 25%;
    height: 100%;
    border-radius:$border-radius;
    }
}

.about-video{
	display: flex;
	align-items: center;
	transition: all 0.5s ease;
	.video{
		position: relative;
		width: 120px;
		border-radius:$border-radius;
		&:before{
			content: "";
			position: absolute;
			top: 0;
			right: 0;
			width: 100%;
			height: 100%;
			background: rgba($gray-9,0.5);
			border-radius:$border-radius;
		}
		img{
			border-radius:$border-radius;
		}
		span{
			position: absolute;
			top: 50%;
			left: 50%;
			transform: translate(-50%,-50%);
			font-size: 56px;
			margin-left: 0;
			color: $primary;
			transition: all 0.5s ease;
		}
	}
	span{
		margin-left: 40px;
		font-size: 16px;
		color: $gray-8;
		transition: all 0.5s ease;
	}
	&:hover{
		span{
			color: $primary;
		}
	}
}

/* Home-03 */

.sarvice-info-box{
	position: relative;
	padding-top: 310px;
}
.video-section {
	margin-bottom: -215px;
	position: relative;
	z-index: 9;
	.about-video{
		.video{
			width: 100%;
			span{
				font-size: 90px;
			}
			&:before{
				background: none;
			}
		}
	}
}
.category-section{
	position: relative;
	padding: 53px 35px 0 0;
	.owl-carousel{
		.owl-dots{
			text-align: left;
			margin-top: 0;
		}
	}
	&:before{
		background: $gray-9;
		position: absolute;
		content: "";
		right: 0;
		top: 0;
		width: 27%;
		height: 97%;
		border-radius: $border-radius;
	}
}

/* ineer page */

.clients-section-02{
	.our-clients{
		background-color: $white;
	}
}
.contact-form {
   padding: 40px;
   background: $white;
   box-shadow: $box-shadow;
   border-radius: $border-radius;
   margin-right: -100px;
   z-index: 9;
   position: relative;
}
 .map{
 	position: relative;
 	z-index: 1;
 	iframe{
 		width: 100%;
 		height: 620px;
 	}
}

/* service-detail */

.category-list .widget{
	padding: 0;
	list-style: none;
	margin-bottom: 0px;
	.widget-title{
		h4{
			margin-bottom: 30px;
		}
	}
	li{
	    border-bottom: 1px solid rgba(102, 102, 102, 0.1);
	    padding-bottom: 15px;
	    margin-bottom: 25px;
	    &:last-child{
			margin: 0;
			border-bottom: 0px;
   			padding-bottom: 0;
	    }
	    a{
		    color: $gray-3;
		    display: block;
		    &:hover{
		    	color: $primary;
		    }
 		}
	}
}

.category-list {
	background: $white;
	padding: 50px 40px;
    box-shadow:$box-shadow;
    border-radius: $border-radius;
    margin-bottom: 40px;
}

.service-add{
	padding: 40px 120px 40px 40px;
	box-shadow:$box-shadow;
	border-radius: $border-radius;
	h4{
		margin-bottom: 20px;
	}
}

.blog-post-author{
	margin-top: 120px;
}

/* team-single */

.team-single{
	background: $gray-9;
	border-radius: $border-radius;
	h4{
		color:$white;
	}
	.contact-form-01{
		padding: 40px 40px;
 	}
 	.team-single-detail{
 		.team-single-img{
			img{
				border-radius:$border-radius;
			}
 		}
		.list-unstyled{
 			padding: 40px 40px 0px 40px;
	 		span{
	 			padding-right:20px;
	 			color:$white;
	 			flex: 0 40%;
	 		}
	 		label{
	 			color:$white;
	 			flex: 0 63%;
	 		}
	 		label.social{
 				a{
 					color: $white;
 					margin-right: 15px;
 				}
	 		}
 		}
 	}
}

.contact-form-01 {
	.form-control{
		background:rgba($white,0.2);
		border:1px solid rgba($white,0.02);
		color:$white;
	}
}


/* Social Bg Color */

.social-bg-hover {
	&:before {
		content: "";
		color: $white;
		width: 100%;
		height: 100%;
		display: inline-block;
		position: absolute;
		left: 0;
		top: 0;
	}
	position: relative;
	color: $white;
	&:hover {
		color: $white;
		&:before {
			background-color: rgba(black, 0.1);
		}
	}
	span {
		position: relative;
	}
}

.facebook-bg {
	background-color: #445c8e;
}

.twitter-bg {
	background-color: #43afe9;
}

.google-bg {
	background-color: #dc0510;
}

.instagram-bg{
	background-color: #DD2A7B;
}

.linkedin-bg {
	background-color: #007eb3;
}


/* services-detail */

.services-detail-slide img{
	border-radius: $border-radius;
}


/* sitter-account */

.sitter-account{
	padding: 40px;
	background: $white;
    box-shadow:$box-shadow;
    border-radius: $border-radius;
    .section-title{
    	margin-bottom: 30px;
    }

    	label{
    		font-size: 16px;
    		color:$gray-8;
    		font-weight: 600;
    	}
    	p{
    		margin-bottom: 8px;
    	}
   .account-info{
   		text-align: center;
   		width: 100%;
   		margin-bottom: 40px;
   		margin-top: 0px;
		.account-separator{
			background-color: #eeeeee;
			height: 1px;
			width: 100%;
			display: inline-block;
		}
    }
}


/* login */

.login{
	.tab-content{
		margin-top: 40px;
	}
}


/* coming-soon */

 .coming-soon .slide-gallery .carousel-item img{
 	height: 100vh;
 	object-fit: cover;
 }

 .coming-soon{
   .slide-gallery .carousel-control-next,
   	.carousel-control-prev{
        display: none;
    }
}

.coming-soon-content{
    padding: 30px;
    color: $white;
    h1{
	    font-size: 32px;
	    color: $white;
	    margin-bottom: 20px;
    }
  	h6{
  		color: $white;
  		margin-bottom: 30px;
  	}
  	.newsletter{
  		.form-control::placeholder{color:$gray-8; }
  		.form-control::-webkit-input-placeholder{color:$gray-8; }
  		.form-control::-ms-input-placeholder{color:$gray-8; }
  	}
}

.social-icon.social-light ul li a:hover{
	background: $white;
}

.countdown.border-right{
	border-color:rgba($white,0.3) !important;
}

.social-icon {
	ul{
		display: inline-block;
		margin-bottom: 0;
		padding-left: 0;
		li{
			display: inline-block;
			list-style-type: none;
			float: left;
			&:last-child{
				a{
					margin-right: 0;
				}
			}
			a{
				display: block;
				background-color:$primary;
				color: $gray-8;
				height: 40px;
				width: 40px;
				line-height: 45px;
				text-align: center;
				border-radius:50%;
				margin-right: 20px;
				transition: all 0.5s ease;
				&:hover{
					background-color:$gray-9;
					color:$primary;
				}
				i{
					font-size: 18px;
				}
			}
		}
	}
}

.header .social-icon{
	text-align: right;
}


/* coming-soon */

.carousel-fade {
   	.carousel-inner {
		.item {
			transition-property: opacity;
		}
		.item,
		.active.left,
		.active.right {
			opacity: 0;
		}
		.active,
		.next.left,
		.prev.right {
			opacity: 1;
		}
		.next,
		.prev,
		.active.left,
		.active.right {
			left: 0;
			transform: translate3d(0, 0, 0);
		}
	}
	.carousel-control {
		z-index: 2;
	}
}


/* locations */

.locations {
	li {
		a {
			font-weight: 600;
			font-size: 18px;
			color: $gray-8;
			&:hover {
				color: $primary;
			}
		}
	}
}

/* sticky column */

.is-sticky-column {
  position: -webkit-sticky;
  position: sticky;
  top: 130px;
}


/* Form */
.form-control {
    padding: 14px 20px;
    height: 48px;
    font-size: 14px;
    border-color: $border-color;
    transition: all 0.3s ease-in-out;
    color: $gray-3;
    border-radius: $border-radius;
    &:focus {
        box-shadow: none;
        border-color: $primary;
    }
}

.custom-control {
    position: relative;
    z-index: 1;
    display: block;
    min-height: 1.5rem;
    padding-left: 1.5rem;
}

.custom-control-input {
    position: absolute;
    left: 0;
    z-index: -1;
    width: 1rem;
    height: 1.25rem;
    opacity: 0;
}
.custom-control-label {
    position: relative;
    margin-bottom: 0;
    vertical-align: top;
}
.custom-control-label::before {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    pointer-events: none;
    content: "";
    background-color: $white;
    border: $gray-2 solid 1px;
}
.custom-control-label::after {
    position: absolute;
    top: .25rem;
    left: -1.5rem;
    display: block;
    width: 1rem;
    height: 1rem;
    content: "";
    background: no-repeat 50%/50% 50%;
}
.custom-checkbox .custom-control-label:before {
    border-radius: .25rem;
    border: 2px solid $gray-2;
}
.custom-checkbox .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8' viewBox='0 0 8 8'%3e%3cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26l2.974 2.99L8 2.193z'/%3e%3c/svg%3e");
}

.custom-radio .custom-control-label::before {
    border-radius: 50%;
}
.custom-radio .custom-control-input:checked~.custom-control-label::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}

textarea.form-control {
    height: auto;
}

.custom-control-input {
    &:checked {
        ~ {
            .custom-control-label {
                &:before {
                    background: $primary;
                    border-color: $primary;
                }
            }
        }
    }
}

.custom-control-input:focus~.custom-control-label::before {
    box-shadow: none;
}

.custom-control-input:focus:not(:checked)~.custom-control-label::before {
    border-color: $primary;
}

.custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: $primary;
    border-color: $primary;
}

.form-check-input:checked {
    background-color: $gray-8;
    border-color: $gray-8;
}

.form-check-input:focus {
	border-color: #283B90;
    box-shadow: none;

}

@media (max-width:767px) {
	.sitter-account {
		padding: 20px;
	}
}
