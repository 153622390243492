/*****************************
  Countdown
*****************************/

.countdown {
	display: inline-block;
	text-align: left;
	margin-right:40px;
	padding-right: 40px;
	span {
		font-size: 36px;
		line-height: 48px;
		font-weight: 600;
		color: $white;
	}
	p {
		font-size: 20px;
		text-transform: capitalize;
		margin-bottom: 0;
		color: $white;
	}
}
