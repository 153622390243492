/*****************************
	Testimonial
*****************************/

.testimonial {
	padding: 70px 45px 30px;
	text-align: center;
	position: relative;
	.testimonial-info{
		padding: 130px 130px 60px;
		border-radius: $border-radius;
		position: relative;
		background-color: $white;
		.testimonial-quote{
			position: absolute;
			top: -75px;
			left: 65px;
			z-index: -1;
	    	i{
	        	font-size: 90px;
	    	    color: rgba( $gray-8,0.2);
	         }
		}
		.testimonial-content {
			font-size: 24px;
			font-style: italic;
			font-weight: 600;
			position: relative;
			margin-bottom: 50px;
		}
		.testimonial-name{
			h6{
				font-weight: 400;
				margin-right: 10px;
				display: inline-block;
			}
			span{
				font-size: 16px;
				font-weight: 400;
				color: $gray-2;
			}
		}
	}
	.avatar{
		position: absolute;
		top: 0px;
		left: 50%;
		transform: translateX(-50%);
		z-index: 99;
		img{
			border-radius: $border-radius;
		}
	}
}


.testimonial-center {
	.owl-item.active {
		.testimonial {
			.testimonial-info {
				box-shadow: $box-shadow;
			}
		}
	}
}


/* testimonial 02 */

.testimonial-02 {
	padding: 70px 0px;
	text-align: center;
    position: relative;
    margin: 0 30px;
	.testimonial-info{
		padding: 70px 40px;
		box-shadow: $box-shadow;
		border-radius: $border-radius;
		position: relative;
		background-color: $white;
		.testimonial-quote{
			position: absolute;
			top: -75px;
			left:50%;
			transform: translateX(-50%);
			z-index: -1;
			i{
	        	font-size: 90px;
	    	    color: rgba( $gray-8,0.2);
	         }
		}
		.testimonial-content {
			font-size: 18px;
			font-style: italic;
			font-weight: 600;
			position: relative;
			margin-bottom: 50px;
		}
		.testimonial-author{
			margin-bottom: -150px;
			.avatar{
				position: inherit;
				top: 0px;
				left:inherit;
				margin-left: 0;
				margin-bottom:20px;
				z-index: 99;
				img{
					border-radius: $border-radius;
				}
			}
			.testimonial-name{
				h6{
					font-weight: 400;
					margin-right: 10px;
					display: inline-block;
				}
				span{
					font-size: 16px;
					font-weight: 400;
					color: $gray-2;
				}
			}
		}
	}
}

@media (max-width:767px) {
	.testimonial-02 {
		margin: 0 20px;
	}
}
