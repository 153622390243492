/*****************************
  Not Found
*****************************/

.error-404-images{
	margin-bottom: 60px;
}
.error-404{
	h1{
	   font-size: 80px;
	   margin-bottom: 30px;
	}
    h4{
		margin-bottom: 35px;
		color: $body-color;
	}
}